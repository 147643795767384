import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import { lazy } from 'react';

const SettingsCompanies = lazy(() => import('pages/portal/settings'));
const Operations = lazy(() => import('pages/portal/operations'));
const Home = lazy(() => import('pages/portal/home'));
const VoiceIntakes = lazy(() => import('pages/portal/voice/voiceIntakes'));
const VoiceDetails = lazy(() => import('pages/portal/voice/voiceDetails'));
const SignedDocument = lazy(() =>
  import('pages/portal/voice/contractSigningGrid')
);
const BotTemplate = lazy(() =>
  import('../features/chatbot/pvt-components/BotTemplate')
);
const generalRoutes = [
  {
    path: '/portal/settings',
    name: 'settings',
    title: 'Settings',
    component: props => <SettingsCompanies {...props} />,
    icon: <UilSetting />,
    exact: true
  },
  {
    path: '/home',
    name: 'home',
    title: 'Home',
    component: props => <Home {...props} />,
    exact: true
  },
  {
    path: '/portal/operations',
    name: 'operations',
    title: 'Operations',
    component: props => <Operations {...props} />,
    exact: true
  },
  {
    path: '/portal/voiceintakes',
    name: 'voiceintakes',
    title: 'Voice Intakes',
    component: props => <VoiceIntakes {...props} />,
    exact: true
  },
  {
    path: '/portal/signeddocuments',
    name: 'signeddocument',
    title: 'Signed Document',
    component: props => <SignedDocument {...props} />,
    exact: true
  },
  {
    path: '/portal/voicedetails/:id',
    name: 'voicedetails',
    title: 'Voice Details',
    component: props => <VoiceDetails {...props} />,
    exact: true
  },
  {
    path: '/portal/bottemplate',
    name: 'bottemplate',
    title: 'Bot Template',
    component: props => <BotTemplate {...props} />,
    exact: true
  }
];

export default generalRoutes;
