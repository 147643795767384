import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { ChatBotAPI } from 'api/endpoints';
import { toast } from 'react-toastify';
import storage from 'services/utils/storage';

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState: {
    chatbot: null,
    businessInfo: null,
    workingDays: null,
    locations: null,
    mustAskQuestions: null,
    questionCategories: null,
    leadQualification: null,
    appointmentBooking: null,
    callConnect: null,
    prompt: null,
    caseTypes: null,
    caseTypesQuestion: null,
    responseTypes: null
  },

  reducers: {
    setChatBotToState: (state, action) => {
      state.chatbot = action.payload;
    },
    updateChatBotState: (state, action) => {
      state.chatbot = { ...state.chatbot, ...action.payload };
      storage.setItem('selected-chatbot', { ...state.chatbot });
    },
    setBusinessInfoToState: (state, action) => {
      state.businessInfo = action.payload;
    },
    updateBusinessInfoState: (state, action) => {
      state.businessInfo = { ...(state.businessInfo || {}), ...action.payload };
    },
    setWorkingDaysToState: (state, action) => {
      state.workingDays = action.payload;
    },
    updateWorkingDaysState: (state, action) => {
      state.workingDays = { ...state.workingDays, ...action.payload };
    },
    setLocationsToState: (state, action) => {
      state.locations = action.payload;
    },
    updateBusinessLocation: (state, action) => {
      const locationId = action.payload?.id;

      const existingIndex = (state.locations || []).findIndex(
        location => location.id === locationId
      );

      if (existingIndex !== -1) {
        state.locations[existingIndex] = {
          ...state.locations[existingIndex],
          ...action.payload
        };
      } else {
        state.locations = [...(state.locations || []), action.payload];
      }
    },
    removeLocationById: (state, action) => {
      const locationId = action.payload;

      state.locations = state.locations.filter(
        location => location.id !== locationId
      );
    },
    setMustAskQuestions: (state, action) => {
      state.mustAskQuestions = action.payload;
    },
    updateMustAskQuestion: (state, action) => {
      const questionId = action.payload?.id;

      const existingIndex = (state.mustAskQuestions || []).findIndex(
        question => question.id === questionId
      );

      if (existingIndex !== -1) {
        state.mustAskQuestions[existingIndex] = {
          ...state.mustAskQuestions[existingIndex],
          ...action.payload
        };
      } else {
        state.mustAskQuestions = [
          ...(state.mustAskQuestions || []),
          action.payload
        ];
      }
    },
    removeQuestionById: (state, action) => {
      const questionId = action.payload;

      state.mustAskQuestions = state.mustAskQuestions.filter(
        question => question.id !== questionId
      );
    },
    setQuestionCategories: (state, action) => {
      state.questionCategories = action.payload;
    },
    addQuestionCategory: (state, action) => {
      state.questionCategories = [...state.questionCategories, action.payload];
    },
    setDataInState: (state, action) => {
      const { key, data } = action.payload;
      state[key] = data;
    },
    updateDataInState: (state, action) => {
      const { key, id, data } = action.payload;

      const existingIndex = (state[key] || []).findIndex(
        item => item.id === id
      );

      if (existingIndex !== -1) {
        state[key][existingIndex] = {
          ...state[key][existingIndex],
          ...data
        };
      } else {
        state[key] = [...(state[key] || []), data];
      }
    },

    removeItemById: (state, action) => {
      const { key, id } = action.payload;
      state[key] = (state[key] || []).filter(item => item.id !== id);
    },

    setDataInStateConversation: (state, action) => {
      const { key, data } = action.payload;
      state[key] = { ...state[key], ...data };
    },
    updateDataInStateConversation: (state, action) => {
      const { key, id, data } = action.payload;
      const type = data.type;
      const existingIndex = (state[key][type] || []).findIndex(
        item => item.id === id
      );

      if (existingIndex !== -1) {
        state[key][type][existingIndex] = {
          ...state[key][type][existingIndex],
          ...data
        };
      } else {
        state[key][type] = [...(state[key][type] || []), data];
      }
    },

    removeItemByIdConversation: (state, action) => {
      const { key, data } = action.payload;
      const { id, type } = data;
      state[key][type] = (state[key][type] || []).filter(
        item => item.id !== id
      );
    },
    clearChatBotStates: state => {
      Object.keys(state).forEach(key => {
        state[key] = null;
      });
    },
    addCaseType: (state, action) => {
      state.caseTypes = [...state.caseTypes, action.payload];
    },
    setCaseTypes: (state, action) => {
      state.caseTypes = action.payload;
    },
    setCaseTypesQuestion: (state, action) => {
      state.caseTypesQuestion = action.payload;
    },
    updateCaseTypesQuestion: (state, action) => {
      const questionId = action.payload?.id;

      const existingIndex = (state.caseTypesQuestion || []).findIndex(
        question => question.id === questionId
      );

      if (existingIndex !== -1) {
        state.caseTypesQuestion[existingIndex] = {
          ...state.caseTypesQuestion[existingIndex],
          ...action.payload
        };
      } else {
        state.caseTypesQuestion = [
          ...(state.caseTypesQuestion || []),
          action.payload
        ];
      }
    },

    deleteCaseTypeQuestionById: (state, action) => {
      const questionId = action.payload;
      state.caseTypesQuestion = state.caseTypesQuestion.filter(
        question => question.id !== questionId
      );
    }
  }
});

export const {
  setChatBotToState,
  updateChatBotState,
  setBusinessInfoToState,
  updateBusinessInfoState,
  setWorkingDaysToState,
  updateWorkingDaysState,
  setLocationsToState,
  updateBusinessLocation,
  removeLocationById,
  clearChatBotStates,
  setMustAskQuestions,
  updateMustAskQuestion,
  removeQuestionById,
  setQuestionCategories,
  addQuestionCategory,
  setDataInState,
  updateDataInState,
  removeItemById,
  setDataInStateConversation,
  updateDataInStateConversation,
  removeItemByIdConversation,
  addCaseType,
  setCaseTypes,
  setCaseTypesQuestion,
  updateCaseTypesQuestion,
  deleteCaseTypeQuestionById
} = chatbotSlice.actions;
export default chatbotSlice.reducer;

export const selectChatbotPrompt = state => {
  return state.chatbot.prompt;
};
export const selectChatBot = state => {
  return state.chatbot.chatbot;
};
export const selectBusinessInfo = state => {
  return state.chatbot.businessInfo;
};
export const selectLeadQualification = (state, type) => {
  return state.chatbot.leadQualification?.[type];
};

export const selectAppointmentBooking = (state, type) => {
  return state.chatbot.appointmentBooking?.[type];
};
export const selectCallConnect = (state, type) => {
  return state.chatbot.callConnect?.[type];
};
export const selectWorkingDays = state => {
  return state.chatbot.workingDays;
};
export const selectLocations = state => {
  return state.chatbot.locations;
};
export const selectMustAskQuestions = state => {
  return state.chatbot.mustAskQuestions;
};
export const selectQuestionCategories = state => {
  return state.chatbot.questionCategories;
};
export const selectCaseTypes = state => {
  return state.chatbot.caseTypes;
};
export const selectCaseTypesQuestion = state => {
  return state.chatbot.caseTypesQuestion;
};
export const selectResponseTypes = state => {
  return state.chatbot.responseTypes;
};

export const clearChatbotLS = () => {
  storage.deleteItem('selected-chatbot');
  storage.deleteItem('chatbot-tab');
  storage.deleteItem('chatbot-path');
  storage.deleteItem('chatbotSubCategories');
  storage.deleteItem('chatbotCaseTypes');
  storage.deleteItem('companiesOptions');
};

export const fetchChatBotById = id => async (dispatch, getState) => {
  const state = getState();
  const existingChatBot = selectChatBot(state);

  // If chatbot is already in the state, resolve with success status
  if (existingChatBot && existingChatBot.id == id) {
    console.log('Chatbot is already in state:', existingChatBot);
    return { success: true }; // Return success status
  }

  try {
    const res = await getChatbotById(id);

    if (res.data?.success && res.data?.data) {
      dispatch(clearChatBotStates());
      dispatch(setChatBotToState(res.data.data));
      return { success: true }; // Return success status
    } else {
      toast.error("You don't have access to specified chatbot");
      return { success: false }; // Return failure status
    }
  } catch (err) {
    toast.error("You don't have access to specified chatbot");
    return { success: false }; // Return failure status
  }
};

export const fetchBusinessInfo = id => (dispatch, getState) => {
  const state = getState();
  const existingInfo = selectBusinessInfo(state);
  if (existingInfo) {
    console.log('business info is already in state:', existingInfo);
    return;
  }
  getBusinessInfoById(id)
    .then(res => {
      if (res.data?.success && res.data?.data) {
        dispatch(setBusinessInfoToState(res.data.data));
      } else {
        throw new Error(res.data?.error);
      }
    })
    .catch(err => {
      console.error(err || 'Error fetching chatbot!');
    });
};

export const fetchWorkingDays = id => (dispatch, getState) => {
  const state = getState();
  const existingInfo = selectWorkingDays(state);
  if (existingInfo) {
    console.log('working days is already in state:', existingInfo);
    return;
  }
  getWorkingDayById(id)
    .then(res => {
      if (res.data?.success && res.data?.data) {
        let data = res.data.data;
        let formattedData = data.map(item => ({
          dayOfWeek: item.dayOfWeek,
          openingTime: item.openingTime,
          closingTime: item.closingTime
        }));
        dispatch(setWorkingDaysToState(formattedData));
      } else {
        throw new Error(res.data?.error);
      }
    })
    .catch(err => {
      console.error(err || 'Error fetching chatbot!');
    });
};

export const fetchBusinessLocation = id => (dispatch, getState) => {
  const state = getState();
  const existingInfo = selectLocations(state);
  if (existingInfo) {
    console.log('business location is already in state:', existingInfo);
    return;
  }
  getCompanyLocationById(id)
    .then(res => {
      if (res.data?.success && res.data?.data) {
        dispatch(setLocationsToState(res.data.data));
      } else {
        throw new Error(res.data?.error);
      }
    })
    .catch(err => {
      console.error(err || 'Error fetching chatbot!');
    });
};

export const fetchMustAskQuestions = id => (dispatch, getState) => {
  const state = getState();
  const existingInfo = selectMustAskQuestions(state);
  if (existingInfo) {
    console.log('questions are already in state:', existingInfo);
    return;
  }
  getQuestionsByBotId(id)
    .then(res => {
      if (res.data?.success && res.data?.data) {
        dispatch(setMustAskQuestions(res.data.data));
      } else {
        throw new Error(res.data?.error);
      }
    })
    .catch(err => {
      console.error(err || 'Error fetching chatbot!');
    });
};

export const fetchReponseTypes = () => (dispatch, getState) => {
  const state = getState();
  const existingInfo = selectResponseTypes(state);
  if (existingInfo) {
    console.log('response types are already in state:', existingInfo);
    return;
  }
  getResponseTypes()
    .then(res => {
      if (res.data?.success && res.data?.data) {
        dispatch(setDataInState({ key: 'responseTypes', data: res.data.data }));
      } else {
        throw new Error(res.data?.error);
      }
    })
    .catch(err => {
      console.error(err || 'Error fetching chatbot!');
    });
};

export const fetchQuestionCategories = id => (dispatch, getState) => {
  const state = getState();
  const existingInfo = selectQuestionCategories(state);
  if (existingInfo) {
    console.log('categories are already in state:', existingInfo);
    return;
  }
  getCategoriesByBotId(id)
    .then(res => {
      if (res.data?.success && res.data?.data) {
        dispatch(setQuestionCategories(res.data.data));
      } else {
        throw new Error(res.data?.error);
      }
    })
    .catch(err => {
      console.error(err || 'Error fetching chatbot!');
    });
};

export const fetchCaseTypes = id => (dispatch, getState) => {
  // const state = getState();
  // const existingInfo = selectCaseTypes(state);
  // if (existingInfo) {
  //   console.log('categories are already in state:', existingInfo);
  //   return;
  // }
  getCaseTypeBySubTypeId(id)
    .then(res => {
      if (res?.data?.success && res?.data?.data) {
        dispatch(setCaseTypes(res?.data?.data));
      } else {
        throw new Error(res.data?.error);
      }
    })
    .catch(err => {
      console.error(err || 'Error fetching chatbot!');
    });
};

export const fetchCaseTypesQuestion = id => (dispatch, getState) => {
  // const state = getState();
  // const existingInfo = selectCaseTypes(state);
  // if (existingInfo) {
  //   console.log('categories are already in state:', existingInfo);
  //   return;
  // }
  getCaseTypeQuestion(id)
    .then(res => {
      if (res?.data?.success && res?.data?.data) {
        dispatch(setCaseTypesQuestion(res?.data?.data));
      } else {
        throw new Error(res.data?.error);
      }
    })
    .catch(err => {
      console.error(err || 'Error fetching chatbot!');
    });
};

export const fetchLeadQualification =
  (id, type, forceFetch = false) =>
  (dispatch, getState) => {
    const state = getState();
    const existingInfo = selectLeadQualification(state, type);
    if (!forceFetch && existingInfo) {
      console.log('lead qualification is already in state:', existingInfo);
      return;
    }
    getLeadsByBotId(id, type)
      .then(res => {
        if (res.data?.success && res.data?.data) {
          dispatch(
            setDataInStateConversation({
              key: 'leadQualification',
              data: { [type]: res.data.data }
            })
          );
        } else {
          throw new Error(res.data?.error);
        }
      })
      .catch(err => {
        console.error(err || 'Error fetching chatbot!');
      });
  };

export const fetchAppointmentBookings =
  (id, type, forceFetch = false) =>
  (dispatch, getState) => {
    const state = getState();
    const existingInfo = selectAppointmentBooking(state, type);
    if (!forceFetch && existingInfo) {
      console.log('appointment booking is already in state:', existingInfo);
      return;
    }
    getAppointmentsByBotId(id, type)
      .then(res => {
        if (res.data?.success && res.data?.data) {
          dispatch(
            setDataInStateConversation({
              key: 'appointmentBooking',
              data: { [type]: res.data.data }
            })
          );
        } else {
          throw new Error(res.data?.error);
        }
      })
      .catch(err => {
        console.error(err || 'Error fetching chatbot!');
      });
  };

export const fetchCallConnects =
  (id, type, forceFetch = false) =>
  (dispatch, getState) => {
    const state = getState();
    const existingInfo = selectCallConnect(state, type);
    if (!forceFetch && existingInfo) {
      console.log('call connect is already in state:', existingInfo);
      return;
    }
    getCallsByBotId(id, type)
      .then(res => {
        if (res.data?.success && res.data?.data) {
          dispatch(
            setDataInStateConversation({
              key: 'callConnect',
              data: { [type]: res.data.data }
            })
          );
        } else {
          throw new Error(res.data?.error);
        }
      })
      .catch(err => {
        console.error(err || 'Error fetching chatbot!');
      });
  };

export const updateBotStatus = status => (dispatch, getState) => {
  const state = getState();
  const chatbot = selectChatBot(state);
  if (chatbot?.id) {
    updateChatbotStatus(chatbot.id, status).then(res => {
      if (res.data?.success) {
        //   dispatch(updateChatBotState({ id: chatbot.id, status: status }));
      } else {
        toast.error('Error updating chatbot status');
      }
    });
  }
};
export const getAppointments = () => {
  let endpoint = ChatBotAPI.appointments;
  return ApiServices.get(endpoint);
};

export const getAppointmentsByBotId = (botId, type) => {
  let endpoint = ChatBotAPI.appointmentByBotId(botId);
  return ApiServices.getWithParams(endpoint, {
    params: { page: 1, pageSize: 200, type: type }
  });
};

export const getChatbotPrompt = botId => {
  let endpoint = ChatBotAPI.chatbotPromptByBot(botId);
  return ApiServices.get(endpoint);
};
export const saveChatbotPrompt = (id, payload) => {
  let endpoint = ChatBotAPI.chatbotPrompt(id);
  return ApiServices.put(endpoint, payload);
};

export const getAppointmentById = id => {
  let endpoint = ChatBotAPI.appointmentById(id);
  return ApiServices.get(endpoint);
};

export const createAppointment = payload => {
  let endpoint = ChatBotAPI.appointments;
  return ApiServices.post(endpoint, payload);
};

export const updateAppointment = (id, payload) => {
  let endpoint = ChatBotAPI.appointmentById(id);
  return ApiServices.put(endpoint, payload);
};

export const deleteAppointment = id => {
  let endpoint = ChatBotAPI.appointments;
  return ApiServices.delete(endpoint, id);
};

export const getChatBotFile = chatBotId => {
  let endpoint = ChatBotAPI.chatbotFileById(chatBotId);
  return ApiServices.get(endpoint);
};

export const postChatBotFile = (chatBotId, file) => {
  let endpoint = ChatBotAPI.chatbotFileById(chatBotId);
  return ApiServices.postAttachements(endpoint, file);
};
export const deleteChatbotWebsite = (chatbotId, indexName, url) => {
  let endpoint = ChatBotAPI.deleteWebsite(chatbotId, indexName, url);
  return ApiServices.delete(endpoint);
};
export const getChatBotWebsite = chatBotId => {
  let endpoint = ChatBotAPI.getChatBotWebsite(chatBotId);
  return ApiServices.get(endpoint);
};
export const postChatbotWebsite = (chatBotId, url) => {
  let endpoint = ChatBotAPI.chatbotWebsite(chatBotId, url);
  return ApiServices.post(endpoint);
};

export const getPluginsByBot = chatBotId => {
  let endpoint = ChatBotAPI.pluginsByBot(chatBotId);
  return ApiServices.get(endpoint);
};

export const generateFromAI = (key, chatbotId) => {
  let endpoint = '';
  switch (key) {
    case 'leadQualification':
      endpoint = ChatBotAPI.leadByBotId(chatbotId);
      break;
    case 'appointmentBooking':
      endpoint = ChatBotAPI.appointmentByBotId(chatbotId);
      break;
    case 'callConnect':
      endpoint = ChatBotAPI.callByBotId(chatbotId);
      break;
  }
  endpoint += '/generate';
  return ApiServices.get(endpoint);
};

export const postPlugin = payload => {
  let endpoint = ChatBotAPI.plugins;
  return ApiServices.post(endpoint, payload);
};

export const putPlugin = (id, payload) => {
  let endpoint = ChatBotAPI.pluginById(id);
  return ApiServices.put(endpoint, payload);
};

export const getCallConnects = chatBotId => {
  let endpoint = ChatBotAPI.callConnectsByBot(chatBotId);
  return ApiServices.get(endpoint);
};

export const postCallConnect = payload => {
  let endpoint = ChatBotAPI.callConnects;
  return ApiServices.post(endpoint, payload);
};

export const putCallConnect = (id, payload) => {
  let endpoint = ChatBotAPI.callConnectById(id);
  return ApiServices.put(endpoint, payload);
};

export const deleteChatBotFile = payload => {
  let endpoint = ChatBotAPI.chatbotFileDelete(payload);
  return ApiServices.delete(endpoint);
};

export const getBusinessCategories = () => {
  let endpoint = ChatBotAPI.categories;
  return ApiServices.getWithParams(endpoint, {
    params: { page: 1, pageSize: 200 }
  });
};

export const getBusinessSubTypes = id => {
  let endpoint = ChatBotAPI.subCategories(id);
  return ApiServices.getWithParams(endpoint, {
    params: { page: 1, pageSize: 200 }
  });
};

export const getCaseTypesBySubCat = ids => {
  let endpoint = ChatBotAPI.caseTypesByCategories;
  return ApiServices.getWithParams(endpoint, {
    params: { subCategoryIds: ids, page: 1, pageSize: 200 }
  });
};

export const getCalls = () => {
  let endpoint = ChatBotAPI.calls;
  return ApiServices.get(endpoint);
};

export const getCallsByBotId = (botId, type) => {
  let endpoint = ChatBotAPI.callByBotId(botId);
  return ApiServices.getWithParams(endpoint, {
    params: { page: 1, pageSize: 200, type: type }
  });
};
export const getCallById = id => {
  let endpoint = ChatBotAPI.callById(id);
  return ApiServices.get(endpoint);
};

export const createCall = payload => {
  let endpoint = ChatBotAPI.calls;
  return ApiServices.post(endpoint, payload);
};

export const updateCall = (id, payload) => {
  let endpoint = ChatBotAPI.callById(id);
  return ApiServices.put(endpoint, payload);
};

export const deleteCall = id => {
  let endpoint = ChatBotAPI.calls;
  return ApiServices.delete(endpoint, id);
};

export const getChatbots = payload => {
  let endpoint = ChatBotAPI.chatbots;
  return ApiServices.getWithParams(endpoint, { params: payload });
};

export const getChatbotById = id => {
  let endpoint = ChatBotAPI.chatbotById(id);
  return ApiServices.get(endpoint);
};

export const createChatbot = payload => {
  let endpoint = ChatBotAPI.chatbots;
  return ApiServices.post(endpoint, payload);
};

export const updateChatbot = (id, payload) => {
  let endpoint = ChatBotAPI.chatbotById(id);
  return ApiServices.put(endpoint, payload);
};

export const getBusinessInfoById = id => {
  let endpoint = ChatBotAPI.businessById(id);
  return ApiServices.get(endpoint);
};

export const addBusinessInfo = payload => {
  let endpoint = ChatBotAPI.businessInfo;
  return ApiServices.post(endpoint, payload);
};

export const updateBusinessInfo = (id, payload) => {
  let endpoint = ChatBotAPI.businessById(id);
  return ApiServices.put(endpoint, payload);
};

export const updateChatbotMode = (chatBotId, value) => {
  let endpoint = ChatBotAPI.chatbotMode(chatBotId, value);
  return ApiServices.patch(endpoint);
};

export const updateChatbotStatus = (chatBotId, value) => {
  let endpoint = ChatBotAPI.chatbotStatus(chatBotId, value);
  return ApiServices.patch(endpoint);
};

export const publishChatbot = chatBotId => {
  let endpoint = ChatBotAPI.chatbotPublish(chatBotId);
  return ApiServices.post(endpoint);
};

export const deleteBusinessInfo = id => {
  let endpoint = ChatBotAPI.businessInfo;
  return ApiServices.delete(endpoint, id);
};

export const getCompanyLocationById = id => {
  let endpoint = ChatBotAPI.locationById(id);
  return ApiServices.get(endpoint);
};

export const addCompanyLocation = payload => {
  let endpoint = ChatBotAPI.companyLocations;
  return ApiServices.post(endpoint, payload);
};

export const updateCompanyLocation = (id, payload) => {
  let endpoint = ChatBotAPI.locationById(id);
  return ApiServices.put(endpoint, payload);
};

export const updateOfficeTimeZoneFunc = payload => {
  let endpoint = ChatBotAPI.updateOfficeTimeZone(payload);
  return ApiServices.patch(endpoint);
};

export const getOfficeTimeZoneFunc = id => {
  let endpoint = ChatBotAPI.getOfficeTimeZone(id);
  return ApiServices.get(endpoint);
};

export const deleteCompanyLocation = id => {
  let endpoint = ChatBotAPI.companyLocations;
  return ApiServices.delete(endpoint, id);
};

export const deleteChatbot = id => {
  let endpoint = ChatBotAPI.chatbots;
  return ApiServices.delete(endpoint, id);
};

export const getManuallQuestion = payload => {
  let endpoint = ChatBotAPI.getManuallQuestionEndPoint(payload);
  return ApiServices.get(endpoint);
};
export const postManuallQuestion = payload => {
  let endpoint = ChatBotAPI.postmanuallQuestion(payload);
  return ApiServices.post(endpoint);
};
export const deleteManuallQuestion = payload => {
  let endpoint = ChatBotAPI.deletemanuallQuestion(payload);
  return ApiServices.delete(endpoint, '');
};
export const updateManuallQuestion = payload => {
  let endpoint = ChatBotAPI.UpdatemanuallQuestion(payload);
  return ApiServices.put(endpoint, '');
};
export const getSessionHistoryById = id => {
  let endpoint = ChatBotAPI.sessionHistory(id);
  return ApiServices.get(endpoint);
};

export const getGreetingMsgById = id => {
  let endpoint = ChatBotAPI.greetingById(id);
  return ApiServices.get(endpoint);
};

export const updateGreetingMsg = (id, payload) => {
  let endpoint = ChatBotAPI.greetingById(id);
  return ApiServices.put(endpoint, payload);
};

export const getWorkingDays = () => {
  let endpoint = ChatBotAPI.workingDays;
  return ApiServices.get(endpoint);
};

export const getWorkingDayById = id => {
  let endpoint = ChatBotAPI.workingDayById(id);
  return ApiServices.get(endpoint);
};

export const createWorkingDay = payload => {
  let endpoint = ChatBotAPI.workingDays;
  return ApiServices.post(endpoint, payload);
};

export const updateWorkingDay = (id, payload) => {
  let endpoint = ChatBotAPI.workingDayById(id);
  return ApiServices.post(endpoint, payload);
};

export const deleteWorkingDay = id => {
  let endpoint = ChatBotAPI.workingDayById(id);
  return ApiServices.delete(endpoint);
};

export const getLeads = () => {
  let endpoint = ChatBotAPI.leads;
  return ApiServices.get(endpoint);
};

export const getLeadsByBotId = (botId, type) => {
  let endpoint = ChatBotAPI.leadByBotId(botId);
  return ApiServices.getWithParams(endpoint, {
    params: { page: 1, pageSize: 200, type: type }
  });
};

export const getLeadById = id => {
  let endpoint = ChatBotAPI.leadById(id);
  return ApiServices.get(endpoint);
};

export const createLead = payload => {
  let endpoint = ChatBotAPI.leads;
  return ApiServices.post(endpoint, payload);
};

export const updateLead = (id, payload) => {
  let endpoint = ChatBotAPI.leadById(id);
  return ApiServices.put(endpoint, payload);
};

export const deleteLead = id => {
  let endpoint = ChatBotAPI.leads;
  return ApiServices.delete(endpoint, id);
};

export const getQuestions = () => {
  let endpoint = ChatBotAPI.questions;
  return ApiServices.get(endpoint);
};

export const getQuestionsByBotId = botId => {
  let endpoint = ChatBotAPI.questionByBotId(botId);
  return ApiServices.getWithParams(endpoint, {
    params: { page: 1, pageSize: 200 }
  });
};

export const getResponseTypes = () => {
  let endpoint = ChatBotAPI.responseTypes;
  return ApiServices.getWithParams(endpoint, {
    params: { page: 1, pageSize: 200 }
  });
};
export const getQuestionById = id => {
  let endpoint = ChatBotAPI.questionById(id);
  return ApiServices.get(endpoint);
};

export const createQuestion = payload => {
  let endpoint = ChatBotAPI.questions;
  return ApiServices.post(endpoint, payload);
};

export const updateQuestion = (id, payload) => {
  let endpoint = ChatBotAPI.questionById(id);
  return ApiServices.put(endpoint, payload);
};

export const deleteQuestion = id => {
  let endpoint = ChatBotAPI.questions;
  return ApiServices.delete(endpoint, id);
};

export const getCategoriesByBotId = id => {
  let endpoint = ChatBotAPI.categoriesByBotId(id);
  return ApiServices.get(endpoint);
};

export const createCategory = payload => {
  let endpoint = ChatBotAPI.categories;
  return ApiServices.post(endpoint, payload);
};

export const getCaseTypeBySubTypeId = id => {
  let endpoint = ChatBotAPI.getCaseTypesById(id);
  return ApiServices.getWithParams(endpoint, {
    params: { page: 1, pageSize: 200 }
  });
};
export const createCaseType = payload => {
  let endpoint = ChatBotAPI.createCaseType;
  return ApiServices.post(endpoint, payload);
};
export const updateCaseType = (id, payload) => {
  let endpoint = ChatBotAPI.updateCaseTypeById(id);
  return ApiServices.put(endpoint, payload);
};

export const deleteCaseType = id => {
  let endpoint = ChatBotAPI.deleteCaseTypeById(id);
  return ApiServices.delete(endpoint);
};

export const updateCategories = (id, payload) => {
  let endpoint = ChatBotAPI.categoryById(id);
  return ApiServices.put(endpoint, payload);
};

export const deleteCategories = id => {
  let endpoint = ChatBotAPI.categoryById(id);
  return ApiServices.delete(endpoint);
};

export const createCaseTypeQuestion = payload => {
  let endpoint = ChatBotAPI.createCaseTypeQuestion;
  return ApiServices.post(endpoint, payload);
};

export const getCaseTypeQuestion = id => {
  let endpoint = ChatBotAPI.getCaseTypeQuestion(id);
  return ApiServices.getWithParams(endpoint, {
    params: { page: 1, pageSize: 200 }
  });
};

export const updateCaseTypeQuestion = (id, payload) => {
  let endpoint = ChatBotAPI.updateCaseTypeQuestion(id);
  return ApiServices.put(endpoint, payload);
};

export const deleteCaseTypeQuestion = id => {
  let endpoint = ChatBotAPI.deleteCaseTypeById(id);
  return ApiServices.delete(endpoint);
}; 

export const sendMsg = payload => {
  let endpoint = ChatBotAPI.sendMessage;
  // let temp = endpoint + '?';
  // Object.keys(payload).forEach(k => {
  //   temp += `&${k}=${payload[k]}`;
  // });
  return ApiServices.post(endpoint, payload);
};
